import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { alertMessage } from './helpers/general-helpers';
import App from '@/App.vue';
import router from '@/router';
import VueApexCharts from 'vue3-apexcharts';
const app = createApp(App);

app.use(VueApexCharts);

app.config.globalProperties.window = window;

window.addEventListener('storage', (event) => {
  if (event.key === 'logout-event') {
    const warningBanner = document.createElement('div');
    warningBanner.classList.add(
      'bg-red-600',
      'text-white',
      'text-center',
      'p-1',
      'fixed',
      'w-full',
      'z-[999]',
      'top-0',
      'font-bold',
    );
    warningBanner.innerText =
      'You have been logged out from another tab. Do not continue working on this tab, otherwise your changes will be lost.';
    document.body.prepend(warningBanner);

    alertMessage(
      'Logged out',
      'You have been logged out from another tab. Do not continue working on this tab, otherwise your changes will be lost.',
      'warning',
      'Okay, go to login',
      () => {
        window.location.href = '/login';
      },
    );
  }
});

if (import.meta.env.MODE === 'production') {
  if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
        'Failed to fetch dynamically imported module',
        'Importing a module script failed',
        'Load failed',
        'Unable to preload CSS',
      ],
    });
  }
}

app.use(createPinia());
app.use(router);
app.mount('#app');
